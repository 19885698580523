<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
				<h3 class="mb-15">{{$t('auth.forgotPassword.title')}}</h3>
				<template v-if="!forgetSendMail">
					<p class="font-regular text-muted">{{$t('auth.forgotPassword.description')}}</p>
					<!-- Sign In Form -->
					<a-form
						id="components-form-demo-normal-login"
						:form="form"
						class="login-form"
						@submit="handleSubmit"
						:hideRequiredMark="true"
					>
						<a-form-item class="mb-10" :label="$t('auth.login.email.placeHolder')" :colon="false">
							<a-input 
							v-decorator="[
							'email',
							{ rules: [{ required: true, message: $t('auth.login.email.required') }] },
							]" :placeholder="$t('auth.login.email.placeHolder')" />
						</a-form-item>
						<a-form-item>
							<a-button :loading="loading" type="primary" block html-type="submit" class="login-form-button">
								{{$t('auth.forgotPassword.btn')}}
							</a-button>
						</a-form-item>
					</a-form>
					<!-- / Sign In Form -->
				</template>
				<template v-else>
					<p class="mt-1" v-html="$t('auth.forgotPassword.notice', {email: email})"></p>
					<a-button @click="$router.push({'name': 'signIn'})" type="primary" block class="login-form-button">
						{{$t('auth.login.btn')}}
					</a-button>
				</template>
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
				<img src="images/img-signin.jpg" alt="">
			</a-col>
			<!-- Sign In Image Column -->

		</a-row>
		
	</div>
</template>

<script>
	import authService from '@/services/auth'
	import { STATUS_ACTIVE } from '@/constant';

	export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" 
				email: '',
				loading: false,
				forgetSendMail: false
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				this.loading = true
				e.preventDefault();
				this.form.validateFields(async (err, values)=> {
					if ( !err ) {
						let formData = new FormData();
						formData.append('email', values.email);

						const res = await authService.forgot(formData)
						if(res.status == STATUS_ACTIVE){
							this.forgetSendMail = true					
						}
					}
					this.loading = false
				});
			},
		},
	})
</script>